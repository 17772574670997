<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>CIDs</b>

        Dialog.dialogApagar(header='Remover CID' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o CID <b>{{ dialogApagar_data.nr_cid }} - {{ dialogApagar_data.ds_cid }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.ds_cid" style='position: relative')
                            .ta-left
                                p <b>Cód. CID:</b> {{ props.data.nr_cid }}
                                p <b>Descrição CID:</b> {{ props.data.ds_cid }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de CIDs')
                DataTable(:value="list")

                    Column(headerStyle='width: 8%;' field='nr_cid' header='Cód. CID')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nr_cid" style='cursor: pointer;') {{ props.data.nr_cid }}

                    Column(headerStyle='width: 25%;' field='ds_cid' header='Descrição CID')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ds_cid" style='cursor: pointer;') {{ props.data.ds_cid }}

                    Column(headerStyle='width: 8%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/unidades-medclub/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                            
        .ta-right.mt-4
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/unidades-medclub/salvar/0/')")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'

    import { CIDS } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        created () { this.applyFilters() },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
					nm_unidade: ''
				},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return CIDS.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        // response.data.results.forEach(unidades => {
                        //     if(unidades.nm_unidade) unidades.nm_unidade_f = unidades.nm_unidade
                        // })
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                CIDS.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('CID removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>